
import { defineComponent, Ref, ref } from 'vue';
import { list, ListHeaderItem, RequestData } from '@/components/common/list';
import { account, accountType } from '@/data';
import Notice from '@/util/notice.base';
import userDialog from '@/components/view/super/user-info-dialog';
import billingInformation from '@/components/view/common/dialog/billing-information';
import { userForSuperApi } from '@/api';

export default defineComponent({
    components: {
        list,
        userDialog,
        billingInformation
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const grade = localStorage.getItem('grade');
        const userInfo: Ref<
            accountType.GetEndUserInfo
        > = ref({
            FamilyMaster: '',
            Name: '',
            Email: '',
            Phone: '',
            Address: '',
            CreateTime: '',
            Role: '',
            ExpireTime: ''
        });
        const getInfo = () => {
            account.getEndUserInfo(props.id, (data: accountType.GetEndUserInfo) => {
                userInfo.value = data;
            });
        };
        getInfo();

        const activeUser = () => {
            Notice.messageBox('confirm', WordList.ProperAllTextActiveAccountTips, '', 'info')(() => {
                userForSuperApi.activeAccount({
                    ID: props.id
                }, () => {
                    getInfo();
                });
            });
        };

        const deviceHeaders: Array<ListHeaderItem> = [{
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation
        }, {
            name: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }];
        const devRequestData: RequestData = {
            url: 'getaccountdev',
            param: {
                ID: props.id
            }
        };

        const userHeaders: Array<ListHeaderItem> = [{
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName
        }, {
            name: 'Email',
            label: WordList.TabelPropertyManageEmail
        }];
        const userRequestData: RequestData = {
            url: 'getaccountapp',
            param: {
                ID: props.id
            }
        };

        const isShowCnfDialog = ref(false);

        const isShowBillInfo = ref(false);
        const getBillingInfo = () => {
            isShowBillInfo.value = true;
        };

        return {
            deviceHeaders,
            devRequestData,
            userHeaders,
            userRequestData,
            userInfo,
            getInfo,
            grade,
            activeUser,
            isShowCnfDialog,
            getBillingInfo,
            isShowBillInfo
        };
    }
});
